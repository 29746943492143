import React, { useEffect, useRef, useState } from "react";
import swal from "sweetalert";
import { debounce } from "lodash";
import SimpleReactValidator from "simple-react-validator";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Col, Row, Image } from "react-bootstrap";
import Api from "../../helper/api";
import AuthApi from "../../helper/authApi";
import DocumentModel from "./documentModel";
import customFunctions from "../../helper/customFunctions";
import { DropdownInput, TextInput, Helmet } from "../../components/FormInputs";
import Breadcrumb from "../../components/Breadcrumb/breadcrumb.component";
import Loader from "../../components/Loader";
import PlaceComponent from "../../components/PlaceComponent";

const IndividualClientOnboardComponent = () => {
  const validator = useRef(new SimpleReactValidator());
  const file: any = useRef();
  const navigate = useNavigate();
  const location = useLocation();

  const [clientId, setClientId] = useState<any>(null);
  const [panCardNumber, setPanCardNumber] = useState<any>("");
  const [basicDetails, setBasicDetails] = useState<any>({
    clientType: "",
    clientName: "",
    contactNumber: "",
    email: "",
    address: "",
  });

  const [city, setCity] = useState<any>("");
  const [state, setState] = useState<any>("");
  const [pincode, setPincode] = useState<any>("");
  const [stateArr, setStateArr] = useState<any>([]);
  const [cityArr, setCityArr] = useState<any>([]);

  const [regulatoryDetails, setRegulatoryDetails] = useState<any>({
    parentCompanyName: "",
    companyIdentificationNumber: "",
    gstNumber: "",
    importExportCode: "",
  });

  const [servicesValues, setServicesValues] = useState<any>([]);
  const [documentValues, setDocumentValues] = useState<any>([]);

  const [documentsDetails, setDocumentsDetails] = useState<any>([
    {
      doc_type_id: null,
      file: "",
      documentsArr: customFunctions.uniqueArray(documentValues, "label"),
      client_document_id: null,
      id: null,
      title: "",
    },
  ]);

  const [servicesDetails, setServicesDetails] = useState<any>([
    {
      service: "",
      billing_type: "",
      id: null,
      serviceArr: servicesValues,
      sac_number: "",
    },
  ]);
  const [title, setTitle] = useState<string>("Create Client");
  const [pageType, setPageType] = useState<string>("add");
  const [panCardErrorMessage, setPanCardErrorMessage] = useState<any>();
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [count, forceUpdate] = useState<number>(0);
  const [show, setShow] = useState<any>(false);
  const [toggle, setToggle] = useState<any>(true);
  const [addressId, setAddressId] = useState<any>("");

  const items: any = [
    { to: "/", label: "Dashboard" },
    { to: "/client-master-list", label: "Clients" },
    {
      label:
        location?.state?.pageType === "add"
          ? "Create Client"
          : location?.state?.pageType === "edit"
          ? "Edit Client"
          : location?.state?.pageType === "view"
          ? "View Client"
          : "Create Client",
    },
  ];

  const billingTypeValues: any = [
    { value: 1, label: "Recurring" },
    { value: 2, label: "One-Time" },
    { value: 3, label: "On-Going" },
  ];

  let startYear = 1999;
  let prevYear = 1998;
  const financialYear: any = [];
  for (let i = 0; i < 100; i++) {
    startYear = startYear + 1;
    prevYear = prevYear + 1;
    financialYear.push({
      value: `${prevYear}-${startYear}`,
      label: `${prevYear}-${startYear}`,
    });
  }

  useEffect(() => {
    getServices();
    getDocumentType();
  }, []);

  useEffect(() => {
    if (location && location?.state && location?.state?.pageType === "add")
      return;
    if (location && location.state) {
      setClientId(location?.state?.clientId);
      if (location.state && location?.state?.pageType === "edit") {
        setTitle("Edit Client Details");
        setPageType("edit");
      }
      if (location.state && location?.state?.pageType === "view") {
        setTitle("View Client Details");
        setPageType("view");
      }
    }
  }, [location]);

  useEffect(() => {
    if (clientId) {
      getClient();
    }
  }, [clientId]);

  const getServices = async () => {
    setShowLoader(true);
    const params = {
      limit: 100,
      sort_direction: "ascending",
      sort_by: "name",
    };

    const url = customFunctions.generateUrl(`${Api.servicesUrl}`, params);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data && data.data.length > 0) {
        let servicesArr: any = [];
        data.data.length > 0 &&
          data.data.map((item: any) => {
            servicesArr.push({
              label: item?.services,
              value: item?.id,
              sac_code: item?.services_sac_code,
            });
          });
        setServicesValues(servicesArr);
      } else {
        setServicesValues([]);
      }
    } else {
      setShowLoader(false);
      swal(message, "", "error");
      return;
    }
  };

  useEffect(() => {
    getState();
  }, []);

  useEffect(() => {
    if (state && state?.value) getCity();
  }, [state]);

  const getState = async () => {
    setShowLoader(true);
    const endPoint: any = Api.stateUrl;
    const params: any = {
      limit: 50,
      sort_by: "name",
    };

    const url: any = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data?.data?.length) {
        const stateData: any = [];
        data?.data?.map((item: any) => {
          stateData.push({
            label: item?.name,
            value: item?.id,
          });
        });
        setStateArr(stateData);
      }
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const getCity = async () => {
    setShowLoader(true);
    const endPoint: any = Api.cityUrl;
    const params: any = {
      limit: 800,
      state_id: state?.value,
      sort_by: "name",
    };

    const url: any = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data?.data?.length) {
        const cityData: any = [];
        data?.data?.map((item: any) => {
          cityData.push({
            label: item?.name,
            value: item?.id,
          });
        });
        setCityArr(cityData);
      }
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const uploadDocument = async (pIndex: any) => {
    setShowLoader(true);
    const formData = new FormData();
    formData.append("file_name", documentsDetails[pIndex].file[0]);

    const endPoint: any = `${Api.documentUploadUrl}`;

    const callback: any = AuthApi.postDataToServer;

    const { data, message } = await callback(endPoint, formData);

    setShowLoader(false);
    if (data && data.success === true) {
      let documentArr: any = [...documentsDetails];
      documentArr[pIndex].client_document_id = data?.data?.id;
      documentArr[pIndex].file = data?.data?.file_name;
      documentArr[pIndex].title = data?.data?.f_name;
      setDocumentsDetails([...documentArr]);
      forceUpdate(count + 1);
      setShowLoader(false);
      setDisableButton(false);
      swal("Document uploaded successfully!", "", "success");
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const getDocumentType = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.documentTypeUrl}`
    );

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data) {
        let documentTypeValues: any = [];
        data &&
          data.data &&
          data.data.map((item: any) => {
            documentTypeValues.push({ label: item?.name, value: item?.id });
          });

        documentTypeValues.push({
          value: "Add New",
          label: (
            <>
              <a className="btn-link">Add New</a>
            </>
          ),
        });
        setDocumentValues(documentTypeValues);
      } else {
        setDocumentValues([]);
      }
    } else {
      setShowLoader(false);
      swal(message, "", "error");
    }
  };

  const getClient = async () => {
    setShowLoader(true);
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.clientUrl}${clientId}/`
    );

    if (data && data.success === true) {
      setShowLoader(false);
      if (data && data.data) {
        let client: any = data.data;
        const serviceValuesArr: any = [];
        if (client?.services?.length > 0) {
          client?.services?.map((ser: any) => {
            serviceValuesArr.push({
              service: {
                label: ser?.service_name,
                value: ser?.service_id,
              },
              billing_type: {
                label: ser?.billing_type,
                value: ser?.billing_type_id,
              },
              id: ser?.id,
              serviceArr: servicesValues,
              sac_number: ser?.service_sac_code,
            });
          });
          setServicesDetails(serviceValuesArr);
        } else {
          setServicesDetails([
            {
              service: "",
              billing_type: "",
              id: null,
              serviceArr: servicesValues,
              sac_number: "",
            },
          ]);
        }

        const documentArr: any = [];
        if (client?.documents?.length > 0) {
          client?.documents?.map((doc: any, index: any) => {
            documentArr.push({
              doc_type_id: {
                label: doc.doc_type_name,
                value: doc.doc_type_id,
              },
              file: doc.Documents.file_name,
              documentsArr: customFunctions.uniqueArray(
                documentValues,
                "label"
              ),
              client_document_id: doc.Documents.id,
              id: doc.id,
              title: doc.Documents.f_name,
            });
          });
          setDocumentsDetails(documentArr);
        } else {
          setDocumentsDetails([
            {
              doc_type_id: null,
              file: "",
              documentsArr: customFunctions.uniqueArray(
                documentValues,
                "label"
              ),
              client_document_id: null,
              id: null,
              title: "",
            },
          ]);
        }

        setPanCardNumber(client.pan_no);
        setBasicDetails({
          clientType: customFunctions.capitalizeFirstLetter(
            client?.client_type_name
          ),
          clientName: customFunctions.capitalizeFirstLetter(
            client?.client_name
          ),
          contactNumber: client?.contact_no_m,
          email: client?.email,
          address: client?.address?.area,
        });

        setState(
          client?.address?.state_id
            ? {
                label: client?.address?.state_name,
                value: client?.address?.state_id,
              }
            : ""
        );

        setCity(
          client?.address?.city_id
            ? {
                label: client?.address?.city_name,
                value: client?.address?.city_id,
              }
            : ""
        );
        setPincode(client?.address?.zipcode ? client?.address?.zipcode : "");

        setRegulatoryDetails({
          parentCompanyName: client?.company_name,
          companyIdentificationNumber: client?.cin_no,
          gstNumber: client?.gst_no,
          importExportCode: client?.iec_no,
        });
      }
    } else {
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const Debounce = debounce(
    (e: any) => pancardVerification(e.target.value),
    500
  );

  const AddNewServices = () => {
    const payload = {
      service: "",
      billing_type: "",
      id: null,
      serviceArr: servicesValues,
      sac_number: "",
    };

    const newData = [...servicesDetails, payload];
    setServicesDetails(newData);
  };

  const DeleteServices = (index: any) => {
    const newdata = [...servicesDetails];
    newdata.splice(index, 1);
    setServicesDetails([...newdata]);
  };

  const AddNewDocument = () => {
    const payload = {
      doc_type_id: null,
      file: "",
      documentsArr: documentValues,
      client_document_id: null,
      id: null,
      title: "",
    };

    const newData = [...documentsDetails, payload];
    setDocumentsDetails(newData);
  };

  const DeleteDocument = (index: any) => {
    let newdata = [...documentsDetails];
    newdata.splice(index, 1);
    setDocumentsDetails(newdata);
    forceUpdate(count + 1);
  };

  let isValid = true;

  const pancardVerification = (e?: any) => {
    const validKey: any = e.charAt(3);

    let pattern = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
    let result = pattern.test(e);

    if (e.length === 0 || result === false) {
      setPanCardErrorMessage(
        e === "" ? "The pan number field is required." : "Invalid pan number"
      );
      forceUpdate(count + 1);
      isValid = false;
    }

    if (isValid) {
      setPanCardErrorMessage("");
      forceUpdate(count + 1);

      switch (validKey) {
        case "P":
          return setBasicDetails({ ...basicDetails, clientType: "Proprietor" });

        case "C":
          return setBasicDetails({ ...basicDetails, clientType: "Corporate" });

        default:
          return setBasicDetails({
            ...basicDetails,
            clientType: "Non-Corporate",
          });
      }
    }
  };

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // documentsDetails.map((item: any) => {
    //   // if (item.file !== "") {
    //   //   validator.current.errorMessages.UploadFile = null;
    //   //   validator.current.fields.UploadFile = true;
    //   // }
    // });
    console.log('validator.current', validator.current)
    if (validator.current.allValid()) {
      addClient();
    } else {
      let errFieldsArr: any = Object.entries(validator?.current?.fields).find(
        (item) => item[1] == false
      );
      let erName = errFieldsArr[0].replace(/ /g, "");
      document.getElementById(`${erName}`)?.focus();
      validator.current.showMessages();
      forceUpdate(count + 1);
    }
  };

  const addClient = async () => {
    setShowLoader(true);
    const documentsDetailsArr: any = [];
    const servicesArr: any = [];
    
    documentsDetails &&
    documentsDetails.length &&
    documentsDetails.map((doc: any, index: any) => {
        console.log();
        if (doc?.doc_type_id?.value){
        documentsDetailsArr.push({
          doc_type_id: doc.doc_type_id.value,
          client_document_id: doc.client_document_id,
        });
        if (clientId) documentsDetailsArr[index].id = doc.id;
      }
      });
    servicesDetails &&
      servicesDetails.length &&
      servicesDetails.map((serv: any, index: any) => {
        servicesArr.push({
          client_services_id: serv.service.value,
          billing_type: serv.billing_type.value,
        });
        if (clientId) servicesArr[index].id = serv.id;
      });

    const postData: any = {
      client_type_name: basicDetails.clientType,
      client_name: basicDetails.clientName,
      pan_no: panCardNumber,
      email: basicDetails.email.trim(),
      mobile: basicDetails.contactNumber,
      address: {
        address_id: addressId,
        area: basicDetails?.address,
        zipcode: pincode,
        state: state?.value,
        city: city?.value,
      },
      services: servicesArr,
      documents: documentsDetailsArr,
      company_name:
        basicDetails?.clientType === "Corporate"
          ? regulatoryDetails?.parentCompanyName
          : null,
      cin_no:
        basicDetails?.clientType === "Corporate"
          ? regulatoryDetails?.companyIdentificationNumber
          : null,
      gst_no:
        // basicDetails?.clientType === "Corporate" || basicDetails?.clientType === "Proprietor"
        regulatoryDetails?.gstNumber ? regulatoryDetails?.gstNumber : null,
      iec_no:
        basicDetails?.clientType === "Corporate"
          ? regulatoryDetails?.importExportCode
          : null,
    };

    if (clientId) {
      postData.id = clientId;
    }

    const endPoint: any = clientId
      ? `${Api.clientUrl}${clientId}/`
      : `${Api.clientUrl}`;

    const callback: any = clientId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;

    const { data, message } = await callback(endPoint, postData);

    if (data && data.success === true) {
      setShowLoader(false);
      setDisableButton(false);
      swal(
        clientId
          ? "Client updated successfully!"
          : "Client created successfully!",
        "",
        "success"
      ).then(() => {
        navigate(-1);
      });
    } else {
      setDisableButton(false);
      setShowLoader(false);
      swal(typeof message == "string" ? message : message[0], "", "error");
    }
  };

  const getDocumentsData = (pIndex: any) => {
    const documentDetailsArr = [...documentsDetails];
    if (documentDetailsArr && documentDetailsArr.length) {
      let filterArr = documentValues.filter((sr: any) => {
        return documentDetailsArr.findIndex(
          (serv: any, i: any) =>
            sr?.value === serv?.doc_type_id?.value && i !== pIndex
        ) === -1
          ? true
          : false;
      });
      documentDetailsArr[pIndex].documentsArr = filterArr?.length
        ? filterArr
        : [];
    }
    setDocumentsDetails(documentDetailsArr);
    forceUpdate(count + 1);
  };

  const getServicesData = (pIndex: any) => {
    const serviceDetailsArr = [...servicesDetails];
    if (serviceDetailsArr && serviceDetailsArr.length) {
      let filterArr = servicesValues.filter((sr: any) => {
        return serviceDetailsArr.findIndex(
          (serv: any, i: any) =>
            sr?.value === serv?.service?.value && i !== pIndex
        ) === -1
          ? true
          : false;
      });
      serviceDetailsArr[pIndex].serviceArr = filterArr?.length ? filterArr : [];
    }
    setServicesDetails(serviceDetailsArr);
    forceUpdate(count + 1);
  };

  const checklistChangeImage = (itm: any) => {
    const newImage: any = documentsDetails.some((item2: any) => {
      if (item2.doc_type_id && item2.client_document_id != null) {
        return Number(itm.value) === Number(item2.doc_type_id.value);
      }
    })
      ? "/images/complete-task-green.svg"
      : "/images/complete-task.svg";
    return newImage;
  };

  const imageNewTab = (e: any, itm: any) => {
    e.preventDefault();
    let newV =
      documentsDetails &&
      documentsDetails.filter((nt: any) => nt.doc_type_id.value == itm.value);
    window.open(newV[0].file);
  };

  return (
    <>
      <Helmet title={title} />
      <div style={{ float: "right" }} className="form-group mt-4">
        <Button
          id="back-btn"
          type="button"
          variant="primary"
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
        >
          Back
        </Button>
      </div>

      <div className="page-header">
        <div className="title-breadcrumb-section">
          <h2 className="main-content-title tx-24 mg-b-5">{title}</h2>
          <Breadcrumb items={items} />
        </div>
      </div>
      <Loader showLoader={isShowLoader} needFullPage={false} />
      <section className="content-area"></section>
      <Card>
        <Card.Body>
          <h6 className="section-title">General Details</h6>
          <hr />

          <Row>
            <Col sm={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  const re = /^[a-zA-Z ]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setBasicDetails({
                      ...basicDetails,
                      clientName: e.target.value,
                    });
                  }
                }}
                label="Client Name"
                asterisk="*"
                containerClass="form-group"
                type="text"
                value={basicDetails.clientName}
                disabled={pageType === "view"}
                placeholder="Enter client name"
                errorMessage={validator.current.message(
                  "clientName",
                  basicDetails.clientName,
                  "required"
                )}
                id="clientName"
                // autoComplete="new-clientName"
              />
            </Col>

            <Col sm={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  const re = /^[0-9a-zA-Z]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setPanCardNumber(e.target.value.toUpperCase());
                    forceUpdate(count + 1);
                    if (e.target.value.length > 9) {
                      Debounce(e);
                      forceUpdate(count + 1);
                    }
                  }
                }}
                label="PAN Number"
                asterisk="*"
                containerClass="form-group"
                type="text"
                name="panCardNumber"
                value={panCardNumber}
                disabled={clientId}
                placeholder="Enter pan number e.g. AAAAA0000A"
                errorMessage={
                  panCardErrorMessage
                    ? panCardErrorMessage
                    : validator.current.message(
                        "PAN number",
                        panCardNumber,
                        "required|regex:^([A-Z]){5}([0-9]){4}([A-Z]){1}$",
                        {
                          messages: {
                            regex: "Invalid PAN number",
                            required: "The PAN number field is required.",
                          },
                        }
                      )
                }
                autoComplete="new-panCardNumber"
                maxlength={10}
                minlength={10}
                id="PANnumber"
              />
            </Col>
            <Col sm={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  const re = /^[a-zA-Z0-9]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setRegulatoryDetails({
                      ...regulatoryDetails,
                      gstNumber: e.target.value.toUpperCase(),
                    });
                  }
                }}
                label="GST Registration Number"
                // containerClass="form-group"
                type="text"
                name="gstNumber"
                value={regulatoryDetails.gstNumber}
                disabled={pageType === "view"}
                placeholder="Enter GST number e.g. 22AAAAA0000A1Z5"
                autoComplete="new-gstNumber"
                maxlength={15}
                minlength={15}
                errorMessage={validator.current.message(
                  "gst number",
                  regulatoryDetails.gstNumber,
                  "regex:^([0-9]){2}([A-Z]){5}([0-9]){4}([A-Z]){1}([A-Z0-9]){1}Z([A-Z0-9]){1}$",
                  {
                    messages: {
                      regex: "Invalid GST number",
                    },
                  }
                )}
                id="gstnumber"
              />
            </Col>

            <Col sm={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  setBasicDetails({
                    ...basicDetails,
                    clientType: e.target.value,
                  });
                }}
                id="clientType"
                label="Client Type"
                placeholder="Client type"
                containerClass="form-group"
                type="text"
                name="clientType"
                value={basicDetails.clientType}
                disabled={clientId || pageType === "add"}
                autoComplete="new-clientType"
              />
            </Col>
          </Row>
          {basicDetails?.clientType === "Corporate" && (
            <>
              <h6 className="section-title">Regulatory Details</h6>
              <hr />
              <Row>
                <Col sm={6}>
                  <TextInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      const re = /^[a-z A-Z & .]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setRegulatoryDetails({
                          ...regulatoryDetails,
                          parentCompanyName: e.target.value,
                        });
                      }
                    }}
                    label="Name Of Parent Company"
                    containerClass="form-group"
                    type="text"
                    name="parentCompanyName"
                    value={regulatoryDetails.parentCompanyName}
                    disabled={pageType === "view"}
                    placeholder="Enter parent company name"
                    autoComplete="new-parentCompanyName"
                  />
                </Col>

                <Col sm={6}>
                  <TextInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      const re = /^[a-zA-Z0-9]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setRegulatoryDetails({
                          ...regulatoryDetails,
                          companyIdentificationNumber:
                            e.target.value.toUpperCase(),
                        });
                      }
                    }}
                    label="Company Identification Number"
                    // containerClass="form-group"
                    type="text"
                    name="companyIdentificationNumber"
                    value={regulatoryDetails.companyIdentificationNumber}
                    disabled={pageType === "view"}
                    placeholder="Enter CIN e.g. L12345AB1234ABC123456"
                    autoComplete="new-companyIdentificationNumber"
                    maxlength={21}
                    minlength={21}
                    errorMessage={validator.current.message(
                      "cinnumber",
                      regulatoryDetails.companyIdentificationNumber,
                      "regex:^([LU]){1}([0-9]){5}([A-Za-z]){2}([0-9]){4}([A-Za-z]){3}([0-9]){6}$",
                      {
                        messages: {
                          regex: "Invalid company identification number",
                        },
                      }
                    )}
                    id="cinnumber"
                  />
                </Col>

                <Col sm={6}>
                  <TextInput
                    onInputChange={(
                      e: React.ChangeEvent<
                        HTMLTextAreaElement | HTMLInputElement
                      >
                    ) => {
                      const re = /^[a-zA-Z0-9]*$/;
                      if (!e.target.value || re.test(e.target.value)) {
                        setRegulatoryDetails({
                          ...regulatoryDetails,
                          importExportCode: e.target.value.toUpperCase(),
                        });
                      }
                    }}
                    label="Import Export Code"
                    containerClass="form-group"
                    type="text"
                    name="importExportCode"
                    value={regulatoryDetails.importExportCode}
                    disabled={pageType === "view"}
                    placeholder="Enter import export code"
                    autoComplete="new-importExportCode"
                    maxlength={10}
                    minlength={10}
                    //   errorMessage={validator.current.message(
                    //     "",
                    //     regulatoryDetails.importExportCode,
                    //     "regex:^([LU]){1}([0-9]){5}([A-Za-z]){2}([0-9]){4}([A-Za-z]){3}([0-9]){6}$",
                    // {
                    //   messages: {
                    //     regex: "Invalid import export code",
                    //   },
                    // }
                    //   )}
                  />
                </Col>
              </Row>
            </>
          )}
          <h6 className="section-title">Services Details</h6>
          <hr />

          {servicesDetails &&
            servicesDetails.length > 0 &&
            servicesDetails.map((item: any, index: any) => (
              <>
                <Row className="">
                  <Col sm={3}>
                    <DropdownInput
                      label={index === 0 ? "Services" : ""}
                      asterisk="*"
                      placeholder="Select service"
                      options={item?.serviceArr}
                      defaultValue={item?.service}
                      disabled={pageType === "view"}
                      onSelectHandler={(select: any) => {
                        let form = [...servicesDetails];
                        form[index].service = select;
                        form[index].sac_number = select?.sac_code;
                        setServicesDetails(form);
                      }}
                      onMenuOpen={() => getServicesData(index)}
                      errorMessage={validator.current.message(
                        `Services`,
                        servicesDetails[index].service,
                        "required",
                        {
                          messages: {
                            required: "The services field is required.",
                          },
                        }
                      )}
                      id={`Services`}
                    />
                  </Col>

                  {/* <Col sm={3}>
                    <DropdownInput
                      label={index === 0 ? "Financial Year" : ""}
                      asterisk="*"
                      placeholder="Select financial year"
                      options={financialYear}
                      defaultValue={item?.financial_year}
                      disabled={pageType === "view"}
                      onSelectHandler={(select: any) => {
                        let form = [...servicesDetails];
                        form[index].financial_year = select;
                        setServicesDetails(form);
                      }}
                      isClearable={true}
                      errorMessage={validator.current.message(
                        "financial year",
                        servicesDetails[index].financial_year,
                        "required"
                      )}
                      id="financial_year"
                    />
                  </Col> */}
                  <Col sm={3}>
                    <TextInput
                      label={index === 0 ? "SAC Number" : ""}
                      containerClass="form-group"
                      type="text"
                      value={item.sac_number}
                      asterisk="*"
                      disabled
                      placeholder="Enter sac number"
                    />
                  </Col>
                  <Col sm={3}>
                    <DropdownInput
                      label={index === 0 ? "Billing Type" : ""}
                      asterisk="*"
                      placeholder="Select"
                      options={billingTypeValues}
                      defaultValue={item.billing_type}
                      disabled={pageType === "view"}
                      onSelectHandler={(select: any) => {
                        let form = [...servicesDetails];
                        form[index].billing_type = select;
                        setServicesDetails(form);
                      }}
                      errorMessage={validator.current.message(
                        `BillingType`,
                        servicesDetails[index].billing_type,
                        "required",
                        {
                          messages: {
                            required: "The billing type field is required.",
                          },
                        }
                      )}
                      id={`BillingType`}
                    />
                  </Col>

                  <Col sm={3}>
                    <div className={index === 0 ? "form-group mt-4" : ""}>
                      {pageType !== "view" && servicesDetails.length > 1 && (
                        // <button
                        //   style={{
                        //     width: "30px",
                        //     height: "30px",
                        //     color: "white",
                        //     backgroundColor: "#24959D",
                        //     border: "#24959D",
                        //   }}
                        //   onClick={(e) => {
                        //     e.preventDefault();
                        //     DeleteServices(index);
                        //   }}
                        //   disabled={disableButton}
                        // >
                        //   <h3>-</h3>
                        // </button>

                        <a
                          className="underline mr-3"
                          title="Remove Service"
                          href="javascript:void(0)"
                          onClick={(e) => {
                            e.preventDefault();
                            DeleteServices(index);
                          }}
                        >
                          Remove
                        </a>
                      )}
                      {pageType !== "view" &&
                        servicesDetails.length - 1 === index &&
                        servicesValues.length !== servicesDetails.length && (
                          // <button
                          //   className="ml-10"
                          //   style={{
                          //     width: "30px",
                          //     height: "30px",
                          //     color: "white",
                          //     backgroundColor: "#24959D",
                          //     border: "#24959D",
                          //   }}
                          //   onClick={(e) => {
                          //     e.preventDefault();
                          //     AddNewServices();
                          //   }}
                          //   disabled={disableButton}
                          // >
                          //   <h3>+</h3>
                          // </button>

                          <a
                            className="underline"
                            title="Add More Service"
                            href="javascript:void(0)"
                            onClick={(e) => {
                              e.preventDefault();
                              AddNewServices();
                            }}
                          >
                            Add More
                          </a>
                        )}
                    </div>
                  </Col>
                </Row>
              </>
            ))}

          <h6 className="section-title">Documents Details</h6>
          <hr />
          <Row>
            <Col sm={9}>
              <div className="justify-content-center mb-4">
                <h6 style={{ fontSize: "smaller" }}>
                  <b>Note:</b>
                </h6>
                <h6 style={{ fontSize: "smaller" }}>
                  1. Maximum upload file size limit is 1 MB.
                </h6>
                <h6 style={{ fontSize: "smaller" }}>
                  2. File supported extension is jpg , png , svg , jpeg , pdf
                </h6>
              </div>
              {documentsDetails &&
                documentsDetails.length > 0 &&
                documentsDetails.map((item: any, index: any) => (
                  <>
                    <Row className="mt-2">
                      <Col sm={4}>
                        <DropdownInput
                          label={index === 0 ? "Document Type" : ""}
                          // asterisk="*"
                          placeholder="Select type"
                          options={item.documentsArr}
                          defaultValue={item?.doc_type_id}
                          disabled={pageType === "view"}
                          onSelectHandler={(select: any) => {
                            if (select?.value === "Add New") {
                              setShow(true);
                              return;
                            }
                            let form = [...documentsDetails];
                            form[index].doc_type_id = select;
                            setDocumentsDetails(form);
                            forceUpdate(count + 1);
                          }}
                          onMenuOpen={() => getDocumentsData(index)}
                          // errorMessage={validator.current.message(
                          //   `documentType${index}`,
                          //   documentsDetails[index].doc_type_id,
                          //   "required",
                          //   {
                          //     messages: {
                          //       required: "The document type field is required.",
                          //     },
                          //   }
                          // )}
                          id={`documentType${index}`}
                        />
                      </Col>
                      <Col sm={4}>
                        <TextInput
                          onInputChange={(e: any) => {
                            let form = [...documentsDetails];
                            form[index].file = e.target.files;
                            setDocumentsDetails(form);
                            // if (e.target.value == "") return;

                            if (
                              e.target.value !== "" &&
                              ["png", "jpeg", "jpg", "svg", "pdf"].includes(
                                typeof e.target.files[0].name === "string" &&
                                  e.target.files[0].name.split(".").pop()
                              )
                            ) {
                              if (e.target.files[0].size >= 1000000) {
                                setShowLoader(false);
                                swal("File size is too large", " ", "error");
                                form[index].file = "";
                                item.title = "";
                                setDocumentsDetails([...form]);
                                return;
                              } else {
                                uploadDocument(index);
                              }
                            } else {
                              swal(
                                "File extension is not supported.",
                                "",
                                "error"
                              );
                              return;
                            }
                            forceUpdate(count + 1);
                          }}
                          label={index === 0 ? "Upload File" : ""}
                          // asterisk={"*"}
                          type="file"
                          name="file"
                          title={item.title}
                          style={{
                            opacity: 0,
                            color: "transparent",
                            width: "70px",
                          }}
                          accept={
                            "image/png, image/jpeg, image/jpg, image/svg, application/pdf"
                          }
                          disabled={pageType === "view"}
                          // errorMessage={
                          //   toggle
                          //     ? validator.current.message(
                          //       `UploadFile${index}`,
                          //         documentsDetails[index].file,
                          //         "required",
                          //         {
                          //           messages: {
                          //             required: "The upload file field is required.",
                          //           },
                          //         }
                          //       )
                          //     : ""
                          // }
                          id={`UploadFile${index}`}
                        />
                        <Button
                          ref={file}
                          variant="secondary"
                          style={{ marginTop: "-63px", width: "210px" }}
                        >
                          {item.title
                            ? item.title && item.title.length > 20
                              ? item.title.slice(0, 20) + "..."
                              : item.title
                            : "Choose File"}
                        </Button>
                        {/* {
                          <p style={{ color: "red", marginTop: "-20px" }}>
                            {" "}
                            {validator.current.message(
                              `UploadFile${index}`,
                              documentsDetails[index].file,
                              "required",
                              {
                                messages: {
                                  required: "The upload file field is required.",
                                },
                              }
                            )}{" "}
                          </p>
                        } */}

                        {item.file !== "" ? (
                          <div className={"mt-1 justify-content-between"}>
                            <Image
                              width={50}
                              height={50}
                              style={{ cursor: "pointer", marginTop: "-15px" }}
                              title={"Click here to view document"}
                              src={
                                ["jpg", "png", "svg", "jpeg"].includes(
                                  typeof item.file == "string" &&
                                    item.file.split(".").pop()
                                )
                                  ? item.file
                                  : "/images/attachment.png"
                              }
                              alt="image"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(item.file);
                              }}
                            />
                          </div>
                        ) : null}
                      </Col>
                      <Col sm={4}>
                        <div className={index === 0 ? "mt-4" : ""}>
                          {pageType !== "view" &&
                            documentsDetails.length > 1 && (
                              // <button
                              //   style={{
                              //     width: "30px",
                              //     height: "30px",
                              //     color: "white",
                              //     backgroundColor: "#24959D",
                              //     border: "#24959D",
                              //   }}
                              //   onClick={(e) => {
                              //     e.preventDefault();
                              //     getDocumentsData(index);
                              //     DeleteDocument(index);
                              //   }}
                              //   disabled={disableButton}
                              // >
                              //   <h3>-</h3>
                              // </button>

                              <a
                                className="underline mr-3"
                                title="Remove Document"
                                href="javascript:void(0)"
                                onClick={(e) => {
                                  e.preventDefault();
                                  getDocumentsData(index);
                                  DeleteDocument(index);
                                }}
                              >
                                Remove
                              </a>
                            )}
                          {pageType !== "view" &&
                            documentsDetails.length - 1 === index &&
                            documentValues.length - 1 !==
                              documentsDetails.length && (
                              // <button
                              //   className="ml-10"
                              //   style={{
                              //     width: "30px",
                              //     height: "30px",
                              //     color: "white",
                              //     backgroundColor: "#24959D",
                              //     border: "#24959D",
                              //   }}
                              //   onClick={(e) => {
                              //     e.preventDefault();
                              //     getDocumentsData(index);
                              //     AddNewDocument();
                              //   }}
                              //   disabled={disableButton}
                              // >
                              //   <h3>+</h3>
                              // </button>

                              <a
                                className="underline mr-3"
                                title="Add More Document"
                                href="javascript:void(0)"
                                onClick={(e) => {
                                  e.preventDefault();
                                  getDocumentsData(index);
                                  AddNewDocument();
                                }}
                              >
                                Add More
                              </a>
                            )}
                        </div>
                      </Col>
                    </Row>
                  </>
                ))}
            </Col>
            <Col sm={3}>
              <Card style={{ width: "13rem", marginTop: "13px" }}>
                <Card.Header>Document Checklist</Card.Header>
                <Card.Body>
                  {documentValues.length > 0 &&
                    documentValues.map((itm: any, index: any) => (
                      // <>
                      <tr>
                        {itm.value !== "Add New" ? (
                          <>
                            <Row>
                              <Col sm={1}>
                                <td>
                                  <Image
                                    // className="ml-10"
                                    src={checklistChangeImage(itm)}
                                    height={"15px"}
                                    width={"15px"}
                                  />
                                </td>
                              </Col>
                              <Col sm={9}>
                                <td className="text-break">
                                  <span
                                    className={
                                      checklistChangeImage(itm) ==
                                      "/images/complete-task-green.svg"
                                        ? "active"
                                        : ""
                                    }
                                    title={
                                      checklistChangeImage(itm) ==
                                      "/images/complete-task-green.svg"
                                        ? "Click here to view document"
                                        : ""
                                    }
                                    onClick={(e: any) => {
                                      imageNewTab(e, itm);
                                    }}
                                  >
                                    {itm?.label}
                                  </span>
                                </td>
                              </Col>
                            </Row>
                          </>
                        ) : null}
                      </tr>
                      // </>
                    ))}
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <h6 className="section-title mt-3">Contact Details</h6>
          <hr />
          <Row>
            <Col sm={6}>
              <TextInput
                onInputChange={(e: any) => {
                  const re = /^[0-9]*$/;
                  if (!e.target.value || re.test(e.target.value)) {
                    setBasicDetails({
                      ...basicDetails,
                      contactNumber: e.target.value,
                    });
                  }
                }}
                label="Contact Number"
                asterisk="*"
                inputVarible={"+91"}
                containerClass="form-group"
                type="tel"
                name="contactNumber"
                value={basicDetails.contactNumber}
                disabled={pageType === "view"}
                placeholder="Enter contact number"
                errorMessage={validator.current.message(
                  "ContactNumber",
                  basicDetails.contactNumber,
                  "required|regex:^([6-9]){1}([0-9]){9}$",
                  {
                    messages: {
                      required: "The contact number field is required",
                      regex: "Invalid contact number",
                    },
                  }
                )}
                autoComplete="new-contactNumber"
                maxlength={10}
                minlength={10}
                id="ContactNumber"
              />
            </Col>

            <Col sm={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  setBasicDetails({
                    ...basicDetails,
                    email: e.target.value.toLowerCase(),
                  });
                }}
                id="EmailAddress"
                label="Email Address"
                asterisk="*"
                containerClass="form-group"
                type="text"
                name="email"
                value={basicDetails.email}
                disabled={pageType === "view"}
                placeholder="Enter email address"
                errorMessage={validator.current.message(
                  "EmailAddress",
                  basicDetails.email,
                  "required|email"
                )}
                autoComplete="new-email"
              />
            </Col>

            <Col sm={6}>
              <TextInput
                onInputChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  setBasicDetails({ ...basicDetails, address: e.target.value });
                }}
                id="address"
                label="Address"
                asterisk="*"
                containerClass="form-group"
                type="text"
                as="textarea"
                maxlength={255}
                name="address"
                value={basicDetails.address}
                disabled={pageType === "view"}
                placeholder="Enter address"
                errorMessage={validator.current.message(
                  "address",
                  basicDetails.address,
                  "required"
                )}
                autoComplete="new-address"
              />
            </Col>
            <Col sm={6}>
              <DropdownInput
                label="State"
                asterisk="*"
                placeholder="Select state"
                defaultValue={state}
                onSelectHandler={(select: any) => {
                  setState(select);
                  setCity("");
                  setPincode("");
                  setCityArr([]);
                }}
                options={stateArr}
                errorMessage={validator.current.message(
                  "state",
                  state,
                  "required"
                )}
                id="state"
              />
            </Col>
            <Col sm={6}>
              <DropdownInput
                placeholder="Select city"
                label="City"
                asterisk="*"
                defaultValue={city}
                onSelectHandler={(select: any) => {
                  setCity(select);
                  setPincode("");
                }}
                options={cityArr}
                disabled={!state}
                errorMessage={validator.current.message(
                  "city",
                  city,
                  "required"
                )}
                id="city"
              />
            </Col>
            <Col sm={6}>
              <TextInput
                onInputChange={(e: any) => {
                  const re = /^[0-9]*$/;
                  setPincode(e.target.value);
                }}
                asterisk="*"
                label="Pincode"
                maxlength={6}
                containerClass="form-group"
                type="text"
                value={pincode}
                placeholder="Enter pincode"
                errorMessage={validator.current.message(
                  "pincode",
                  pincode,
                  "required"
                )}
                id="pincode"
              />
            </Col>
          </Row>

          {pageType !== "view" && (
            <Col>
              <div style={{ float: "left" }} className="form-group mt-2">
                <Button
                  id="submitBtn"
                  type="submit"
                  variant="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    onSubmit(e);
                    setToggle(false);
                  }}
                  // disabled={disableButton}
                >
                  Submit
                </Button>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                  variant="secondary"
                  className="ms-2"
                  // disabled={disableButton}
                >
                  Cancel
                </Button>
              </div>
            </Col>
          )}
        </Card.Body>
      </Card>

      {show && (
        <DocumentModel
          show={show}
          handleClose={() => setShow(!show)}
          callBack={() => getDocumentType()}
          data={documentValues}
        />
      )}
    </>
  );
};

export default IndividualClientOnboardComponent;
