import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { BarElement } from "chart.js";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const RevenueChartComponent = (props: any) => {
  const {
    year,
    revenueData,
    callback,
    financialSixMonths,
    indexes,
  } = props;

  const currentMonth = new Date().getMonth() + 1;
  const [monthlyRvenueAmount, setMonthlyRvenueAmount] = useState<any>("");
  const [labels, setLabels] = useState<any>("");

  useEffect(() => {
    if (revenueData) {
      revenueAmount();
    }
  }, [revenueData, financialSixMonths]);

  useEffect(() => {
    if (financialSixMonths?.length) {
      setLabels(financialSixMonths);
    }
  }, [financialSixMonths]);

  const revenueAmount = () => {
    let monthlyAmount: any = [];

    revenueData?.monthly_paid_revenue?.map((item: any, index: number) => {
      for (let key in item) {
        if (!["from_financial_year", "to_financial_year"].includes(key)) {
          monthlyAmount.push(item[key]);
        }
      }
    });

    const latestSixMonthsAmount = monthlyAmount.slice(
      indexes && indexes?.first ? indexes?.first : currentMonth - 9,
      indexes && indexes?.last ? indexes?.last : currentMonth - 3
    );

    const checkMonthsRevenue = latestSixMonthsAmount.every(
      (value: any) => value == 0
    );
    
    callback(checkMonthsRevenue ? true : false);
    setMonthlyRvenueAmount(latestSixMonthsAmount);
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Month",
        display: true,
        data: monthlyRvenueAmount,
        borderColor: "#0AA2C0",
        backgroundColor: "#0AA2C0",
        borderWidth: 1,
      },
    ],
  };

  const options: any = {
    responsive: true,
    scales: {
      y: {
        title: {
          display: true,
          text: "Amount",
          color: "black",
          font: {
            size: 14,
            family: "Roboto",
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: false,
        text: "Revenue Forecast: Linear  Trendline",
      },
    },
  };

  return (
    <>
      {monthlyRvenueAmount ? (
        <Bar data={data} options={options} />
      ) : (
        <div className="mt-5">
          <center>
            {" "}
            <h3>No records found!</h3>
          </center>
        </div>
      )}
    </>
  );
};

export default RevenueChartComponent;
